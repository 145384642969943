import { Box, Flex, chakra, useColorModeValue, Skeleton } from '@chakra-ui/react';
import clamp from 'lodash/clamp';
import React from 'react';

import getStylesByColorScheme from 'theme/utils/getStylesByColorScheme';

interface Props {
  className?: string;
  value: number;
  colorScheme?: string;
  isLoading?: boolean;
}

const Utilization = ({ className, value, colorScheme = 'gray', isLoading }: Props) => {
  const valueString = (clamp(value * 100 || 0, 0, 100)).toLocaleString(undefined, { maximumFractionDigits: 2 }) + '%';
  const colorGrayScheme = useColorModeValue('gray.100', 'gray.100');
  const bgColor = colorScheme === 'gray' ? colorGrayScheme : getStylesByColorScheme(colorScheme).bg;

  return (
    <Flex className={ className } w="100%" alignItems="center" columnGap={ 2 }>
      <Skeleton isLoaded={ !isLoading } w="100%" h="16px" borderRadius="full" overflow="hidden">
        <Box bg="blue.600" h="100%">
          <Box bg={ bgColor } w={ valueString } h="100%"/>
        </Box>
      </Skeleton>
    </Flex>
  );
};

export default React.memo(chakra(Utilization));
